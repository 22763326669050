import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Link } from "@mui/material";

export default function MenuPage({ scrollToComponent }) {
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  return (
    <div className="m-40">
      <Box sx={{ flexGrow: 1 }} spacing={2}>
        <Grid container spacing={10} align="center" justify="center">
          {/* <Grid item xs={12}>
            <Link
              color="inherit"
              href="/projects"
              underline={currentPage === "/projects" ? "always" : "none"}
              sx={{ textUnderlineOffset: 5 }}
            >
              Projects
            </Link>
          </Grid> */}
          <Grid item xs={12}>
            <Link
              color="inherit"
              href=""
              onClick={scrollToComponent}
              underline={currentPage === "/contact" ? "always" : "none"}
              sx={{ cursor: "pointer" }}
            >
              Contact
            </Link>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
