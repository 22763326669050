import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function Title() {
  return (
    <React.Fragment>
      <Box
        sx={{
          paddingY: 24,
          paddingX: { xs: 4, sm: 8, md: 12, lg: 16, xl: 24 },
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box>
              <Typography
                variant="h1"
                align="center"
                sx={{
                  fontSize: {
                    xs: 48,
                    sm: 96,
                  },
                }}
              >
                Josh Nguyen
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography
                variant="h2"
                align="center"
                sx={{
                  fontSize: {
                    xs: 32,
                  },
                }}
              >
                Software Developer
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
