import { AppBar, IconButton, Link, Stack, Toolbar, Box } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import React, { useEffect, useRef, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LightDarkModeButton from "./LightDarkModeButton";
import LinkedInButton from "./LinkedInButton";
import GitHubButton from "./GitHubButton";

export default function NavBar({
  scrollToComponent,
  isMenuOpen,
  setIsMenuOpen,
  mode,
  setMode,
}) {
  const [currentPage, setCurrentPage] = useState("");
  const [navBackground, setNavBackground] = useState("transparent");
  const [elavation, setElavation] = useState(0);

  const ref = useRef(null);

  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleScroll = () => {
    const show = window.scrollY > 64;

    if (show) {
      setNavBackground("solid");
      setElavation(1);
    } else {
      setNavBackground("transparent");
      setElavation(0);
    }
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isExtraSmall) {
      setIsMenuOpen(false);
    }
  }, []);

  return (
    <React.Fragment>
      <AppBar
        ref={ref}
        elevation={elavation}
        sx={{
          bgcolor:
            mode === "dark"
              ? navBackground === "transparent"
                ? "transparent"
                : "rgb(30, 41, 59, 0.85)"
              : navBackground === "transparent"
              ? "transparent"
              : "rgb(241, 245, 249, 0.85)",
          backdropFilter: navBackground === "transparent" ? null : "blur(16px)",
        }}
      >
        <Toolbar justify="flex-end">
          <IconButton
            href="/"
            size="large"
            egde="start"
            color="inherit"
            aria-label="logo"
            sx={{ marginRight: "auto" }}
          >
            <HomeIcon />
          </IconButton>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Link
              color="inherit"
              onClick={scrollToComponent}
              underline={currentPage === "/contact" ? "always" : "none"}
              sx={{ cursor: "pointer" }}
            >
              Contact
            </Link>
            <GitHubButton />
            <LinkedInButton />
            <LightDarkModeButton mode={mode} setMode={setMode} />
          </Stack>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

// {/* {isExtraSmall ? (
//             <React.Fragment>
//               {isMenuOpen ? (
//                 <IconButton
//                   size="large"
//                   egde="start"
//                   color="inherit"
//                   aria-label="logo"
//                   disableRipple={true}
//                   onClick={() => {
//                     setIsMenuOpen(false);
//                   }}
//                 >
//                   <CloseIcon />
//                 </IconButton>
//               ) : (
//                 <IconButton
//                   size="large"
//                   egde="start"
//                   color="inherit"
//                   aria-label="logo"
//                   disableRipple={true}
//                   onClick={() => {
//                     setIsMenuOpen(true);
//                   }}
//                 >
//                   <MenuIcon />
//                 </IconButton>
//               )}
//               <LightDarkModeButton mode={mode} setMode={setMode} />
//             </React.Fragment>
//           ) : ( */}

//  {/* <Link
//             color="inherit"
//             href="/projects"
//             underline={currentPage === "/projects" ? "always" : "none"}
//             sx={{ textUnderlineOffset: 5 }}
//           >
//             Projects
//           </Link> */}
