import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Modal,
  IconButton,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import React from "react";
import emailjs from "@emailjs/browser";

export default function ContactForm({
  isFormOpen,
  handleClose,
  isExtraSmall,
  setIsNavShowing,
  setIsFooterShowing,
}) {
  const isSmallScreen = useMediaQuery("(min-width:700px)");

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [enableFirstNameError, setEnableFirstNameError] = useState(false);
  const [enableSecondNameError, setEnableSecondNameError] = useState(false);
  const [enableEmailError, setEnableEmailError] = useState(false);
  const [enableMessageError, setEnableMessageError] = useState(false);

  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isAlert, setIsAlert] = useState(false);

  const resetFormOnClose = () => {
    resetForm();
    setIsAlert(false);
    handleClose();
  };

  const resetForm = () => {
    setEnableFirstNameError(false);
    setEnableSecondNameError(false);
    setEnableEmailError(false);
    setEnableMessageError(false);
    setFirstName("");
    setSecondName("");
    setEmail("");
    setMessage("");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pcyii1r",
        "template_tytj4ec",
        e.target,
        "n81rBajx64a64hdQ0"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    resetForm();
    setIsAlert(true);
  };

  const isValidEmail = (str) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(str);
  };

  useEffect(() => {
    if (isExtraSmall && isFormOpen) {
      setIsNavShowing(false);
      setIsFooterShowing(false);
    } else {
      setIsNavShowing(true);
      setIsFooterShowing(true);
    }
  }, [isExtraSmall, isFormOpen]);

  useEffect(() => {
    if (
      firstName === "" ||
      secondName === "" ||
      email === "" ||
      message === "" ||
      !isValidEmail(email)
    ) {
      setIsButtonEnabled(false);
    } else {
      setIsButtonEnabled(true);
    }
  }, [firstName, secondName, email, message]);

  useEffect(() => {
    if (firstName !== "") {
      setEnableFirstNameError(true);
    }
    if (secondName !== "") {
      setEnableSecondNameError(true);
    }
    if (email !== "") {
      setEnableEmailError(true);
    }
    if (message !== "") {
      setEnableMessageError(true);
    }
  }, [firstName, secondName, email, message]);

  useEffect(() => {
    if (!email && enableEmailError) {
      setEmailErrorMessage("Email address is required.");
    } else if (!isValidEmail(email) && enableEmailError) {
      setEmailErrorMessage("Please enter a valid email address.");
    } else {
      setEmailErrorMessage("");
    }
  }, [email, enableEmailError]);

  return (
    <div>
      {isFormOpen && isExtraSmall ? (
        <Box
          component="form"
          autoComplete="off"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          onSubmit={sendEmail}
          method="POST"
        >
          <div className="mt-3">
            <Grid container spacing={3}>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography variant="h6">Contact</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  size="large"
                  egde="start"
                  color="inherit"
                  aria-label="logo"
                  onClick={resetFormOnClose}
                  sx={{ padding: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  fullWidth
                  type="text"
                  multiline
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={!firstName && enableFirstNameError}
                  helperText={
                    !firstName && enableFirstNameError
                      ? "First name is required."
                      : ""
                  }
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Second Name"
                  name="secondName"
                  fullWidth
                  type="text"
                  multiline
                  value={secondName}
                  onChange={(e) => setSecondName(e.target.value)}
                  error={!secondName && enableSecondNameError}
                  helperText={
                    !secondName && enableSecondNameError
                      ? "Second name is required."
                      : ""
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email Address"
                  name="email"
                  fullWidth
                  type="text"
                  multiline
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={(!email || !isValidEmail(email)) && enableEmailError}
                  helperText={emailErrorMessage}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  name="message"
                  fullWidth
                  type="text"
                  multiline
                  rows={6}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  error={!message && enableMessageError}
                  helperText={
                    !message && enableMessageError
                      ? "Please write a message."
                      : ""
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} align={"center"}>
                <Button
                  type="submit"
                  variant="outlined"
                  sx={{ height: 56, width: 100 }}
                  disabled={!isButtonEnabled}
                >
                  Send
                </Button>
              </Grid>
              {isAlert ? (
                <Grid item xs={12} align={"center"}>
                  <Alert severity="success">Successfully sent!</Alert>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </Box>
      ) : (
        <Modal
          open={isFormOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              width: isSmallScreen ? 700 : "100%",
            }}
            component="form"
            autoComplete="off"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onSubmit={sendEmail}
            method="POST"
          >
            <div className="mt-3">
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography variant="h6">Contact</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size="large"
                    egde="start"
                    color="inherit"
                    aria-label="logo"
                    onClick={resetFormOnClose}
                    sx={{ padding: 0 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    fullWidth
                    type="text"
                    multiline
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={!firstName && enableFirstNameError}
                    helperText={
                      !firstName && enableFirstNameError
                        ? "First name is required."
                        : ""
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Second Name"
                    name="secondName"
                    fullWidth
                    type="text"
                    multiline
                    value={secondName}
                    onChange={(e) => setSecondName(e.target.value)}
                    error={!secondName && enableSecondNameError}
                    helperText={
                      !secondName && enableSecondNameError
                        ? "Second name is required."
                        : ""
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email Address"
                    name="email"
                    fullWidth
                    type="text"
                    multiline
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={(!email || !isValidEmail(email)) && enableEmailError}
                    helperText={emailErrorMessage}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    name="message"
                    fullWidth
                    type="text"
                    multiline
                    rows={6}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    error={!message && enableMessageError}
                    helperText={
                      !message && enableMessageError
                        ? "Please write a message."
                        : ""
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12} align={"center"}>
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={{ height: 56, width: 100 }}
                    disabled={!isButtonEnabled}
                  >
                    Send
                  </Button>
                </Grid>
                {isAlert ? (
                  <Grid item xs={12} align={"center"}>
                    <Alert severity="success">Successfully sent!</Alert>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}
