import React from "react";
import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import GitHubButton from "./GitHubButton";
import LinkedInButton from "./LinkedInButton";

export default function Footer({ isMenuOpen }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <React.Fragment>
      <Box padding={8} sx={{ flexGrow: 1 }} spacing={2}>
        {isMenuOpen && !matches ? (
          <Grid container spacing={2} align="center" justify="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Typography>© 2023 by Josh Nguyen</Typography>
            </Grid>
            <Grid item xs={6}>
              <GitHubButton />
              <LinkedInButton />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} align="center" justify="center">
            <Grid item xs={12} sm={6} md={4}>
              <Stack>
                <Typography>EMAIL</Typography>
                <Typography>joshuapgnguyen98@gmail.com</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack>
                <Typography>PHONE</Typography>
                <Typography>+44 (0) 7482 731639</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack>
                <Typography>ADDRESS</Typography>
                <Typography>London, United Kingdom</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Typography>© 2023 by Josh Nguyen</Typography>
            </Grid>
            <Grid item xs={6}>
              <GitHubButton />
              <LinkedInButton />
            </Grid>
          </Grid>
        )}
      </Box>
    </React.Fragment>
  );
}
