import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ProjectsPage from "./pages/ProjectsPage";
import ProjectPage from "./pages/ProjectPage";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState, useEffect, useRef } from "react";
import data from "./data/ProjectData.json";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import NotFound from "./components/NotFound";

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [isNavShowing, setIsNavShowing] = useState(true);
  const [isFooterShowing, setIsFooterShowing] = useState(true);
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem("theme");
    return savedMode ? savedMode : "light";
  });

  const theme = createTheme({
    palette: {
      primary:
        mode === "dark"
          ? {
              main: "#1e293b",
            }
          : {
              main: "#f1f5f9",
            },
      secondary:
        mode === "dark"
          ? {
              main: "#0f172a",
            }
          : {
              main: "#e2e8f0",
            },
      text:
        mode === "dark"
          ? { primary: "#f1f5f9", secondary: "#e2e8f0" }
          : { primary: "#1e293b", secondary: "#0f172a" },
      background:
        mode === "dark"
          ? {
              default: "#1e293b",
              paper: "#1e293b",
            }
          : {
              default: "#f1f5f9",
              paper: "#f1f5f9",
            },
      divider:
        mode === "dark"
          ? {
              background: "#1e293b",
            }
          : {
              background: "#f1f5f9",
            },
      button:
        mode === "dark"
          ? {
              main: "#f1f5f9",
            }
          : { main: "#1e293b" },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    typography: {
      fontFamily: ["Open Sans, sans-serif"].join(","),
    },
  });

  const isSmallOrLarger = useMediaQuery(theme.breakpoints.up("sm"));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setProjectData(data);
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode]);

  const ref = useRef(null);
  const scrollToComponent = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="h-screen">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isNavShowing ? (
          <NavBar
            scrollToComponent={scrollToComponent}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            mode={mode}
            setMode={setMode}
          />
        ) : null}
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route
            path="/"
            element={
              <HomePage
                ref={ref}
                scrollToComponent={scrollToComponent}
                isMenuOpen={isMenuOpen}
                isExtraSmall={isExtraSmall}
                setIsNavShowing={setIsNavShowing}
                setIsFooterShowing={setIsFooterShowing}
              />
            }
          />
          {/* <Route
            path="/projects"
            element={
              <ProjectsPage
                isMenuOpen={isMenuOpen}
                isSmallOrLarger={isSmallOrLarger}
                projectData={projectData}
              />
            }
          />
          {projectData.map((project) => {
            return (
              <Route
                key={project.id}
                path={`/projects/${project.title}`}
                element={
                  <ProjectPage
                    isMenuOpen={isMenuOpen}
                    isSmallOrLarger={isSmallOrLarger}
                    projectData={project}
                  />
                }
              />
            );
          })} */}
        </Routes>
        {isFooterShowing ? <Footer isMenuOpen={isMenuOpen} /> : null}
      </ThemeProvider>
    </div>
  );
}

export default App;
