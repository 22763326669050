import React from "react";
import MenuPage from "../components/MenuPage";
import Contact from "../components/Contact";
import Resume from "../components/Resume";
import { forwardRef } from "react";
import Title from "../components/Title";
import Introduction from "../components/Introduction";
import Education from "../components/Education";
import Experience from "../components/Experience";
import Extracurricular from "../components/Extracurricular";

function HomePage(
  {
    isMenuOpen,
    isExtraSmall,
    setIsNavShowing,
    setIsFooterShowing,
    scrollToComponent,
  },
  ref
) {
  return (
    <React.Fragment>
      {isMenuOpen && isExtraSmall ? (
        <MenuPage scrollToComponent={scrollToComponent} />
      ) : (
        <React.Fragment>
          <Title />
          <Introduction />
          <Education />
          <Experience />
          <Extracurricular />
          <Contact
            ref={ref}
            isExtraSmall={isExtraSmall}
            setIsNavShowing={setIsNavShowing}
            setIsFooterShowing={setIsFooterShowing}
          />
          <Resume />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default forwardRef(HomePage);
