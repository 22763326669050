import React from "react";
import DownloadButton from "../components/DownloadButton";
import { Box, Grid, Typography } from "@mui/material";

export default function Resume() {
  return (
    <React.Fragment>
      <Box
        padding={24}
        sx={{
          paddingX: { xs: 8, sm: 8, md: 12, lg: 16, xl: 24 },
          bgcolor: "primary.main",
        }}
      >
        <Grid container spacing={12}>
          <Grid item xs={12} align="center">
            <Typography variant="h4">
              Want to see my professional journey on a single page?
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <DownloadButton />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
