import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";

export default function LinkedInButton() {
  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        href="https://www.linkedin.com/in/josh-nguyen-7a4491221/"
        target="_blank"
        aria-label="LinkedIn"
      >
        <LinkedInIcon />
      </IconButton>
    </React.Fragment>
  );
}
