import React from "react";
import { IconButton } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

export default function LightDarkModeButton({ mode, setMode }) {
  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        disableRipple={true}
        aria-label="modetoggle"
        onClick={() => {
          setMode((prevMode) => {
            const newMode = prevMode === "light" ? "dark" : "light";
            localStorage.setItem("theme", newMode);
            return newMode;
          });
        }}
      >
        {mode === "light" ? <LightModeIcon /> : <DarkModeIcon />}
      </IconButton>
    </React.Fragment>
  );
}
