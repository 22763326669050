import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <React.Fragment>
      <Box padding={24}>
        <Grid spacing={12} container>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              This page doesn't exist!
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
