import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import { IconButton } from "@mui/material";

export default function GitHubButton() {
  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        href="https://github.com/jpgnguyen"
        target="_blank"
        aria-label="Github"
      >
        <GitHubIcon />
      </IconButton>
    </React.Fragment>
  );
}
