import React from "react";
import ContactForm from "../components/ContactForm";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useState } from "react";
import { forwardRef } from "react";

function Contact({ isExtraSmall, setIsNavShowing, setIsFooterShowing }, ref) {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleOpen = () => {
    setIsFormOpen(true);
  };
  const handleClose = () => {
    setIsFormOpen(false);
  };

  return (
    <Box
      ref={ref}
      padding={24}
      sx={{
        paddingX: { xs: 8, sm: 8, md: 12, lg: 16, xl: 24 },
        scrollMarginTop: "64px",
        bgcolor: "secondary.main",
      }}
    >
      <Grid container spacing={12}>
        <Grid item xs={12} align="center">
          <Typography variant="h4">Get in touch!</Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            sx={{ color: "button.main", bgcolor: "primary.main" }}
            onClick={handleOpen}
          >
            Contact Me
          </Button>
        </Grid>
      </Grid>
      <ContactForm
        isFormOpen={isFormOpen}
        handleClose={handleClose}
        isExtraSmall={isExtraSmall}
        setIsNavShowing={setIsNavShowing}
        setIsFooterShowing={setIsFooterShowing}
      />
    </Box>
  );
}

export default forwardRef(Contact);
// {isFormOpen && isExtraSmall ? null : (
//     )}
