import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function handleDownload() {
  return (
    <React.Fragment>
      <Link
        to="/files/JoshuaNguyenCV.pdf"
        download
        target="_blank"
        rel="noreferrer"
      >
        <Button sx={{ color: "button.main", bgcolor: "secondary.main" }}>
          Resume
        </Button>
      </Link>
    </React.Fragment>
  );
}
